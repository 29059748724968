let autocomplete;
let address1Field;
let address2Field;
let postalField;
let cityField;

function initAutocomplete() {
    address1Field = document.querySelector("#ship-address");
    address2Field = document.querySelector("#address2");
    postalField = document.querySelector("#postcode");
    cityField = document.querySelector("#locality");
    // Create the autocomplete object, restricting the search predictions to
    // addresses in the US and Canada.
    autocomplete = new google.maps.places.Autocomplete(address1Field, {
        componentRestrictions: {country: "CA"},
        fields: ["address_components", "geometry"],
        types: ["address"],
    });
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    autocomplete.addListener("place_changed", fillInAddress);
}

function fillInAddress() {
    // Get the place details from the autocomplete object.
    const place = autocomplete.getPlace();
    let apt_number = '';
    let address1 = "";
    let postcode = "";
    let city = "";
    let sublocality = "";
    let province = "";

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    for (const component of place.address_components) {
        // @ts-ignore remove once typings fixed
        const componentType = component.types[0];

        switch (componentType) {
            case "subpremise": {
                apt_number = `${component.long_name} ${address1}`;
                break;
            }

            case "street_number": {
                address1 = `${component.long_name} ${address1}`;
                break;
            }

            case "route": {
                address1 += ' ' + component.long_name;
                break;
            }

            case "premise": {
                address1 += ' ' + component.long_name;
                break;
            }

            case "neighborhood": {
                address1 += ' ' + component.long_name;
                break;
            }

            case "postal_code": {
                postcode = `${component.long_name}${postcode}`;
                break;
            }

            case "postal_code_prefix": {
                postcode = `${component.long_name}${postcode}`;
                break;
            }

            case "postal_code_suffix": {
                postcode = `${postcode}${component.long_name}`;
                break;
            }

            case "locality":
                city = component.long_name;
                break;

            case "sublocality_level_1":
                address1 += ' ' + component.long_name;
                sublocality = component.long_name;
                break;

            case "administrative_area_level_1": {
                province = component.short_name;
                break;
            }
        }
    }

    if (province == 'ON') {
        address1Field.value = address1;
        if (city || sublocality) {
            if (sublocality) {
                cityField.value = sublocality;
            } else {
                cityField.value = city;
            }
        }

        if (postcode) {
            postalField.value = postcode.replace(/\s/g, '');
        } else {
            postalField.value = $(postalField).data('postal')
        }
        $('.address-error').addClass('d-none');
        $(address1Field).removeClass('border-danger');
        address2Field.value = apt_number;
        address2Field.focus();
    }else{
        $(address1Field).addClass('border-danger');
        address1Field.value = '';
        cityField.value = '';
        $('.address-error').removeClass('d-none');
    }

    $('.address').trigger('change');
    // After filling the form with address components from the Autocomplete
    // prediction, set cursor focus on the second address line to encourage
    // entry of subpremise information such as apartment, unit, or floor number.
}

window.initAutocomplete = initAutocomplete;