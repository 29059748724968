$(document).on("turbolinks:load", function() {
    $('.choose-btn').on('click', function () {
        if($('.choose-btn.active').length < 1 && $('.selected-item').length < 1) {
            $('#acceptModal').modal('show')
        }
        $('.choose-btn').removeClass('active');
        $(this).addClass('active');
        $('.select-type-btn').attr('disabled', false);
        // $('.choose-type').addClass('d-none');
        var  type = $('.choose-btn.active').data('type')
        $('.items-container').addClass('d-none')
        $('.'+ type + '-items').removeClass('d-none')
        $('.select-type-btn').addClass('d-none')
        $('.select-item-btn').removeClass('d-none')
        $('html, body').animate({scrollTop: $('.'+ type + '-items').offset().top});
        // $('html, body').animate({scrollTop: 400});
    });

    $('.select-type-btn').on('click', function () {
        $('.choose-type').addClass('d-none');
        var  type = $('.choose-btn.active').data('type')
        $('.'+ type + '-items').removeClass('d-none')
        $('.select-type-btn').addClass('d-none')
        $('.select-item-btn').removeClass('d-none')
        $('html, body').animate({scrollTop: 0});
    });

    $('.back-btn').on('click', function () {
        $('.items-container').addClass('d-none');
        $('.choose-type').removeClass('d-none');
        $('.select-type-btn').removeClass('d-none')
        // $('.select-item-btn').addClass('d-none')
    });

    $('.add-item').on('click', function () {
        btn = this
        var object = new Object();
        object['name'] = $(this).data('name')
        object['type'] = $('.choose-btn.active').data('type')
        $.ajax({
            url: '/carts/add_item',
            type: 'post',
            data: object,
            dataType: 'script',
            success: function(response) {
                $(btn).addClass('active');
                if($(".instruction-input").val() != '') {
                    $('.select-item-btn').attr('disabled', false);
                }
                $('.no-item').addClass('d-none');
                $('.top-border').removeClass('d-none');
                $(btn).attr('disabled', true);
                $(".price-modal-show").removeClass('disabled text-secondary')
            }
        });
    });

    $('.selected-container').on('click', '.quantity-button', function () {
        var item_id = $(this).parent().data('id');

        if ($(this).hasClass('quantity-add')) {
            var addValue = parseInt($(this).parent().find('input').val()) + 1;
            if( addValue > 99 ) {
                addValue = 99;
            }
            $(this).parent().find('input').val(addValue).trigger('change');
        }

        if ($(this).hasClass('quantity-remove')) {
            var removeValue = parseInt($(this).parent().find('input').val()) - 1;
            if( removeValue == 0 ) {
                removeValue = 1;
            }
            $(this).parent().find('input').val(removeValue).trigger('change');
        }

    });

    $('.selected-container').on('change', '.quantity-input', function () {
        var item_id = $(this).parent().data('id');
        if($(this).val() < 1 ||  !$(this).val() ) {
            $(this).val(1);
        }

        if($(this).val() > 99 ) {
            $(this).val(99);
        }

        var object = new Object();
        object['quantity'] = $(this).val()
        $.ajax({
            url: '/carts/' + item_id + '/update_quantity',
            type: 'post',
            data: object,
            dataType: 'script',
            success: function(response) {
            }
        });
    });

    $('.selected-container').on('click', '.remove-btn', function () {
        var item_id = $(this).parent().data('id');

        var object = new Object();
        object['quantity'] = $(this).val()
        $.ajax({
            url: '/carts/' + item_id + '/remove_item',
            type: 'post',
            data: object,
            dataType: 'script',
        });
    });

    // $('.province-select').niceSelect('destroy');

    $(".price-modal-show").click(function(event){
        event.preventDefault();
        if($(".price-modal-show").hasClass("disabled")){
            event.stopPropagation()
        }else {
            $('#priceModal').modal("show");
        }
    });

    // junk removal by item
    $('.select-removal-item').on('click', function (){
        btn = this
        var object = new Object();
        object['product_id'] = $(this).data('id')
        object['type'] = $(this).data('type')
        $.ajax({
            url: '/carts/add_removal_item',
            type: 'post',
            data: object,
            dataType: 'script',
            success: function(response) {
                $(btn).addClass('active');
                $('.select-item-btn').attr('disabled', false);
                $('.no-item').addClass('d-none');
                $('.top-border').removeClass('d-none');
                $(btn).attr('disabled', true);
                $(".price-modal-show").removeClass('disabled text-secondary')
            }
        });
    });

    // reward page
    $('.reward-container').on('click', function (e) {
        var product_id = $(this).data('id')
        $('#check-' + product_id).trigger('click');
    });

    $('.add-product').on('click', function (e) {
        $('.add-product').prop('checked', false);
        $('.add-product').attr('disabled', false);
        $('.reward-container').removeClass('active');
        $(this).prop('checked', true);
        $(this).attr('disabled', true);
    })


    $('.add-product').on('change', function (e) {
        var product_id = $(this).data('id');
        if($(this).is(":checked")){
            btn = this
            $('.product-' + product_id).addClass('active');
            var object = new Object();
            object['product_id'] = product_id
            $.ajax({
                url: '/carts/add_reward_item',
                type: 'post',
                data: object,
                dataType: 'script',
                success: function(response) {
                    $(btn).prop('checked', true);
                    $(btn).attr('disabled', true);
                    $('.next-btn').html('Next')
                }
            });
        }
    })

    $('.control-btn').on('click', function (e) {
        e.stopPropagation();
    })

    $('.choose-charity').on('click', function () {
        $('.choose-charity').removeClass('active');
        $('.choose-charity').attr('disabled', false);
        btn = this
        $(this).addClass('active');
        var  id = $(this).data('id')
        var object = new Object();
        object['charity_id'] = id
        $.ajax({
            url: '/carts/add_charity_donation',
            type: 'post',
            data: object,
            dataType: 'script',
            success: function(response) {
                $(btn).addClass('active');
                $(btn).attr('disabled', true);
                $('.next-btn').html('Next')
            }
        });
    });

    // contact info
    $('#ship-address').on('click', initAutocomplete)

    $('.postal-code').keyup(function(){
        $(this).val($(this).val().toUpperCase());
    });

    function validatePostal() {
        postal_code = $('.postal-code').val();
        if(/^[ML]\d[A-Z]\d[A-Z]\d$/.test(postal_code)){
            $('.postal-error').addClass('d-none');
            $('.postal-code').removeClass('invalid')
        }else {
            $('.postal-error').removeClass('d-none');
            $('.postal-code').addClass('invalid')
        }
    };


    $('.postal-code').change(function (){
        validatePostal();
    });

    formValidation();
    validatePostal();
    function formValidation(){
        var empty = false;
        $('.required').each(function() {
            if ($(this).val() == '') {
                empty = true;
            }
        });

        if($('.invalid').length > 0){
            empty = true;
        }


        if (empty) {
            $('.contact-next').attr('disabled', 'disabled'); // updated according to http://stackoverflow.com/questions/7637790/how-to-remove-disabled-attribute-with-jquery-ie
        } else {
            $('.contact-next').removeAttr('disabled'); // updated according to http://stackoverflow.com/questions/7637790/how-to-remove-disabled-attribute-with-jquery-ie
        }
    }

    $('.address-container').on("keyup change", '.required', function() {
        formValidation()
    });

    $("input:radio[name='contact_type']").on('change click', function (){
        if($(this).val() == 'commercial'){
            $('.residential_options').addClass('d-none')
            $('.condo').prop("checked", false)
            $('.business-name-container').removeClass('d-none');
            $('.business-name').addClass('required');
            $('.business-name').attr('required', true);
        }else {
            $('.residential_options').removeClass('d-none')
            $('.condo').prop("checked", true)
            $('.business-name-container').addClass('d-none');
            $('.business-name').removeClass('required');
            $('.business-name').attr('required', false);
            $('.business-name').val('');
        }
        formValidation()
    });

    // add truck items
    $('.add-truck-item').on('click', function () {
        btn = this
        var object = new Object();
        object['name'] = $(this).data('name')
        object['type'] = 'junk_removal_by_truck'
        $('.add-truck-item').removeClass('active');
        $('.add-truck-item').attr('disabled', false);
        $(btn).addClass('active');
        $(btn).attr('disabled', true);
        $.ajax({
            url: '/carts/add_truck_item',
            type: 'post',
            data: object,
            dataType: 'script',
            success: function(response) {
                $(btn).addClass('active');
                $(btn).attr('disabled', true);
                $(".price-modal-show").removeClass('disabled text-secondary')
            }
        });
    });
});