// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap'
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
window.WOW = require('wowjs').WOW;

require('packs/app/owl.js')
// require('packs/app/wow.js')
require('packs/app/validation.js')
require('packs/app/jquery.fancybox.js')
require('packs/app/appear.js')
require('packs/app/isotope.js')
require('packs/app/parallax-scroll.js')
// require('packs/app/jquery.nice-select.min.js')
require('packs/app/jQuery.style.switcher.min.js')
require('packs/app/gmaps.js')
// require('packs/app/map-helper.js')
require('packs/app/script.js')
require('packs/cart.js')
require('packs/address.js')

// import $ from 'jquery'
// require("select2")
// import 'select2'
// import 'select2/dist/css/select2.css';

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on('turbolinks:load', function() {
    $.ajaxSetup({
        headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('.item-search').select2({
        placeholder: "Search Items",
    });

    $('.item-search').on("select2:selecting", function(e) {
        var object = new Object();
        object['product_id'] = e.params.args.data.id
        object['type'] = 'junk_removal_by_item'
        $.ajax({
            url: '/carts/add_removal_item',
            type: 'post',
            data: object,
            dataType: 'script',
            success: function(response) {
                $(btn).addClass('active');
                $('.select-item-btn').attr('disabled', false);
                $('.no-item').addClass('d-none');
                $('.top-border').removeClass('d-none');
                $(btn).attr('disabled', true);
                $(".price-modal-show").removeClass('disabled text-secondary')
            }
        });
    });

});
